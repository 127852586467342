import React, { createRef, useState } from "react";
import VideoCard from "../video_card/VideoCard";
import "./video.css"
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import config from "../../config";
import Loader from "../loader";
import Modal from "../modal";
import Toaster from "../toast";

export default ({ userInfo }) => {
  const { state = {} } = useLocation();
  const history = useHistory();
  const { videoId } = useParams();
  const mobileOptionsRef = createRef();
  const mobileInfoRef = createRef();

  const [isLoading, setLoadingStatus] = useState(false);
  const [shouldShare, showShareModal] = useState(false);
  const [sharedEmail, setSharedEmail] = useState();
  const [notification, setNotification] = useState("");
  const [record, setRecord] = useState(state.record || {});

  const handleDeleteRecord = async () => {
    setLoadingStatus(true);
    try {
      await fetch(`${config.BASE_ENDPOINT}/api/record/${record._id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${userInfo.jwtAccessToken}`
        }
      }).then(resp => resp.json());

    } catch (error) {
      console.log(error);
    } finally {
      setLoadingStatus(false);
      history.push("/");
    }
  };

  const copyLink = () => {
    var tempInput = document.createElement("input");
    tempInput.style = "position: absolute; left: -1000px; top: -1000px";
    tempInput.value = window.location.origin + "/sent/" + record._id;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    setNotification("link copied successfully");
    setTimeout(() => {
      setNotification("");
    }, 10000);
  };

  const toggleShareModal = () => showShareModal(!shouldShare);

  const shareVideo = async (e) => {
    e.preventDefault();
    setLoadingStatus(true);
    try {
      await fetch(`${config.BASE_ENDPOINT}/api/record/share/${record._id}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userInfo.jwtAccessToken}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: sharedEmail
        })
      }).then(res => res.json());
      toggleShareModal();
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingStatus(false);
    }
  };

  useEffect(() => {
    const getRecordById = async () => {
      setLoadingStatus(true);
      try {
        const result = await fetch(`${config.BASE_ENDPOINT}/api/record/user/${videoId}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userInfo.jwtAccessToken}`
          }
        }).then(resp => resp.json());
        setRecord(result);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingStatus(false);
      }
    };

    if (!Object.keys(record).length) {
      getRecordById();
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getModalBody = () => (
    <form onSubmit={shareVideo}>
      <p className="share__modal__header">Share Video</p>
      <input onChange={e => setSharedEmail(e.target.value)} type="email" placeholder="Enter email address" required></input>
      <div className="share__modal__actions">
        <button className="share__modal__button" type="submit" disabled={isLoading}>Share</button>
        <button onClick={copyLink} className="no__border no__background pointer__cursor share__modal__copy__link">Copy Link</button>
      </div>
    </form>
  );

  const closeNotification = () => {
    setNotification("");
  };

  return (
    isLoading ?
      <Loader /> :
      <main className="video__wrapper">
        {
          shouldShare && <Modal toggle={toggleShareModal}>{getModalBody()}</Modal>
        }
        {
          notification && <Toaster message={notification} onClose={closeNotification} />
        }
        <section className="main__container video__main__container">
          <div className="video__container__header__mobile">
            <p className="regular__text__bold">{state?.record?.name}</p>
            <button className="no__border no__background" onClick={() => { mobileOptionsRef.current.classList.toggle("hidden"); }}><i className="options__icon"></i></button>
          </div>
          <div style={{ width: "60%" }} className="container__header">
            <div className="container__header__options tooltip">
              <span className="tooltiptext">Download</span>
              <a href={record.downloadLink} rel="noopener noreferrer" target="_blank" download>
                <i className="header__options__icon download" data-text="Download">
                </i>
              </a>
            </div>
            <div className="container__header__options tooltip">
              <span className="tooltiptext">Share</span>
              <button className="no__border no__background" type="button" onClick={toggleShareModal}>
                <i className="header__options__icon share"></i>
              </button>
            </div>
            <div className="container__header__options tooltip">
              <span className="tooltiptext">Delete</span>
              <button className="no__border no__background" type="button" onClick={handleDeleteRecord}>
                <i style={{ width: "19px" }} className="header__options__icon delete"></i>
              </button>
            </div>
          </div>
          <div style={{ marginTop: "24px" }} className="container__body">
            <VideoCard {...record} large />
            <aside className="float__block__right" style={{ width: "35%" }}>
              <div className="share__video">
                <div>
                  <p className="regular__text__bold">Send Your Video</p>
                </div>
                <div>
                  <p className="regular__text" style={{ marginTop: "20px", lineHeight: "1" }}>{`${(window.location.origin + "/sent/" + record._id).slice(0, 50)}...`}</p>
                  <button onClick={copyLink} type="button" className="copy__button">Copy link</button>
                </div>
              </div>
            </aside>
          </div>
          <div ref={mobileOptionsRef} className="mobile__video__options hidden" onClick={() => { mobileOptionsRef.current.classList.toggle("hidden"); }}>
            <ul onClick={e => { e.stopPropagation(); }}>
              <li><a href={record.downloadLink} rel="noopener noreferrer" target="_blank" download><i className="nav__icon download"></i>Download</a></li>
              <li><button onClick={copyLink} className="no__border no__background"><i className="nav__icon link"></i>Copy link</button></li>
              <li><button onClick={() => { toggleShareModal(); mobileOptionsRef.current.classList.toggle("hidden"); }} className="no__border no__background"><i className="nav__icon share"></i>Share</button></li>
              <li><button onClick={handleDeleteRecord} className="no__border no__background"><i className="nav__icon delete"></i>Delete</button></li>
              <li><button onClick={() => { mobileInfoRef.current.classList.toggle("hidden"); mobileOptionsRef.current.classList.add("hidden"); }} className="no__border no__background"><i className="nav__icon info" style={{ marginRight: "36px" }}></i>Info</button></li>
            </ul>
          </div>
          <div ref={mobileInfoRef} className="mobile__video__options hidden" onClick={() => { mobileInfoRef.current.classList.toggle("hidden"); }}>
            <ul style={{ height: "265px" }} onClick={e => { e.stopPropagation(); }}>
              <li>
                <span className="regular__text">Video name</span>
                <p style={{ margin: "0", lineHeight: "0" }} className="regular__text__bold">{record.name}</p>
              </li>
              <li>
                <span className="regular__text">Created</span>
                <p style={{ margin: "0", lineHeight: "0" }} className="regular__text__bold">{new Date(record.created_at).toLocaleDateString()}</p>
              </li>
              <li>
                <span className="regular__text">Owner</span>
                <p style={{ margin: "0", lineHeight: "0" }} className="regular__text__bold">{record.name}</p>
              </li>
            </ul>
          </div>
        </section>
      </main>
  )
}