import React from "react";
import "./modal.css";

export default props => {

  return (
    <section className="backdrop">
      <div className="modal">
        <button onClick={props.toggle} style={{ float: "right", marginTop: "5%", marginRight: "5%" }} type="button" className="link__button">
          <i style={{ width: "14px", height: "15px" }} className="modal__icon close"></i>
        </button>
        <div className="modal__body">
          {props.children}
        </div>
      </div>
    </section>
  )
}