import React, { useState, createRef } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import hexcord_logo from "../../images/hexcord_logo.svg";
import config from "../../config";
import constants from "../../constants";
import "./header.css";

let userInfo;

try {
  userInfo = localStorage.getItem("userInfo");
  userInfo = JSON.parse(userInfo);
} catch (error) {
  console.error(error);
  userInfo = {};
}

const Header = props => {
  userInfo = Object.keys(props.userInfo || {}).length ? props.userInfo : userInfo;

  const currentLoc = useLocation().pathname;
  const mobileNavRef = createRef();
  const mobileProfileRef = createRef();
  const [activeNav, setActiveNav] = useState(1);
  const [activeOption, setActiveOption] = useState();
  const [searchText, setSearchText] = useState("");

  const getActiveClass = key => activeNav === key ? "active" : undefined;

  const isShown = option => activeOption === option ? "" : "hidden";

  const searchByName = async (e) => {
    e.preventDefault();
    props.dispatch({ type: constants.IS_LOADING, isLoading: true });

    try {
      const result = await fetch(`${config.BASE_ENDPOINT}/api/record/search?param=${searchText}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${(userInfo || {}).jwtAccessToken}`
        }
      }).then(resp => resp.json());

      props.dispatch({
        type: /shared/i.test(currentLoc) ? constants.GET_SHARED_VIDEOS : constants.GET_MY_VIDEOS,
        payload: result
      });
    } catch (error) {
      console.error(error);
    } finally {
      props.dispatch({ type: constants.IS_LOADING, isLoading: false });
    }
  };

  document.body.onclick = (e) => {
    if (e.target.classList.contains("nav__button") ||
      e.target.classList.contains("nav__icon") ||
      e.target.getAttribute("data-propagate") === "false") {
      return;
    }

    setActiveOption(undefined);
  };

  return (
    <header className={`${/^\/video/i.test(currentLoc) ? "hidden__mobile" : ""}`}>
      <nav className="horus__navbar">
        <div className="navbar__header"><Link to="/"><img className="navbar__logo" src={hexcord_logo} alt="Horus Logo"></img>excord</Link></div>
        <div className="mobile__navbar__options">
          <button type="button" className="nav__button" aria-label="hamburger" onClick={() => { mobileNavRef.current.classList.toggle("hidden"); }}><i className="nav__icon hamburger"></i></button>
        </div>
        <ul className="main__navbar">
          <li className={getActiveClass(1)}><Link to="/" onClick={() => setActiveNav(1)}>My Videos</Link></li>
          <li className={getActiveClass(2)}><Link to="/shared" onClick={() => setActiveNav(2)}>Shared with me</Link></li>
        </ul>
        <div className="navbar__profile">
          <ul>
            <li>
              <button
                type="button"
                className="nav__button"
                aria-label="searchDropdown"
                onClick={() => {
                  setActiveOption(activeOption === "S" ? undefined : "S");
                }}>
                <i className={`nav__icon search ${activeOption === "S" ? "active" : ""}`}></i>
              </button>
              <ul id="searchDropdown" style={{ width: "450px" }} className={`dropdown-menu ${isShown("S")}`} aria-labelledby="searchDropdown">
                <li className="dropdown-item" data-propagate={false}>
                  <form onSubmit={searchByName} data-propagate={false}>
                    <button data-propagate={false} type="submit" className="nav__button" aria-label="search"><i className="nav__icon search" style={{ verticalAlign: "middle" }}></i></button>
                    <input data-propagate={false} type="text" value={searchText} onChange={e => setSearchText(e.target.value)} />
                  </form>
                </li>
              </ul>
            </li>
            <li>
              <button
                type="button"
                className="nav__button"
                aria-label="notification"
                onClick={() => {
                  setActiveOption(activeOption === "N" ? undefined : "N");
                }}>
                <i
                  style={{ width: "21px", height: "25px" }}
                  className={`nav__icon notification  ${activeOption === "N" ? "active" : ""}`}></i>
              </button>
              <ul id="notification" style={{ width: "450px" }} className={`dropdown-menu ${isShown("N")}`} aria-labelledby="notification">
                <p>Notifications</p>
                {
                  props.notifications.map((notification, i) => (
                    <li className="dropdown-item" key={i}>
                      <div>
                        <div>
                          <i className="nav__icon link"></i>
                        </div>
                        <div>
                          <div>{notification.message.title}. <NavLink to={`/sent/${notification.message.recordId}`}>Click here</NavLink> to view.</div>
                          <div><span>30 mins ago</span></div>
                        </div>
                      </div>
                    </li>
                  ))
                }
              </ul>
            </li>
            <li>
              <button
                type="button"
                className="nav__button"
                id="accountDropdown"
                onClick={() => {
                  setActiveOption(activeOption === "P" ? undefined : "P");
                }}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                aria-label="profile">
                <i style={{
                  width: "50px",
                  height: "50px",
                  marginBottom: "-14px",
                  [userInfo?.user?.picture ? "background" : ""]: `url(${userInfo?.user?.picture}) center no-repeat`,
                  borderRadius: "50%",
                  backgroundSize: "cover"
                }}
                  className={`nav__icon avatar ${activeOption === "P" ? "active" : ""}`}></i>
              </button>
              <ul id="accountDropdown" className={`dropdown-menu account ${isShown("P")}`} aria-labelledby="accountDropdown">
                <li className="dropdown-item">
                  <NavLink to="/">
                    {userInfo?.user?.name}
                  </NavLink>
                  <span>{userInfo?.user?.email}</span>
                </li>
                <li className="dropdown-item border-top">
                  <button type="button" className="no__border no__background signout" onClick={() => {
                    localStorage.clear();
                    window.location.pathname = "/login";
                  }}>
                    Sign out
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div ref={mobileNavRef} className="mobile__navbar mobile__video__options hidden" onClick={() => { mobileNavRef.current.classList.toggle("hidden"); }}>
          <ul onClick={e => { e.stopPropagation(); }} style={{ lineHeight: "5" }}>
            <li style={{ display: "block" }}><Link to="/" onClick={() => { mobileNavRef.current.classList.toggle("hidden"); }}><i className="nav__icon video"></i>My Videos</Link></li>
            <li style={{ display: "block" }}><Link to="/shared" onClick={() => { mobileNavRef.current.classList.toggle("hidden"); }}><i className="nav__icon link"></i>Shared with me</Link></li>
            <li style={{ display: "block" }}><button className="no__border no__background"><i className="nav__icon notification" style={{ height: "25px" }}></i>Notifications</button></li>
            <li style={{ display: "block" }}><button className="no__border no__background" onClick={() => { mobileNavRef.current.classList.add("hidden"); mobileProfileRef.current.classList.remove("hidden"); }}><i className="nav__icon user"></i>Profile</button></li>
          </ul>
        </div>
        <div ref={mobileProfileRef} className="mobile__video__options hidden" onClick={() => { mobileProfileRef.current.classList.toggle("hidden"); }}>
          <ul onClick={e => { e.stopPropagation(); }} style={{ height: "180px", lineHeight: "1" }}>
            <li className="dropdown-item" style={{ display: "block", paddingBottom: "15px", borderBottom: "1px solid #E6E6FF", marginTop: "40px", marginRight: "20px" }}>
              <NavLink to="/">
                {userInfo?.user?.name}
              </NavLink>
              <span>{userInfo?.user?.email}</span>
            </li>
            <li className="dropdown-item" style={{ display: "block", marginTop: "20px", marginRight: "20px" }}>
              <button type="button" className="no__border no__background signout" onClick={() => {
                localStorage.clear();
                window.location.pathname = "/login";
              }}>
                Sign out
                  </button>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;