import React, { useState } from "react";
import moment from "moment";
import config from "../../config";
import avatar from "../../images/video_avatar.svg";
import "./video_card.css";
import Toaster from "../toast";
import { NavLink, useHistory } from "react-router-dom";

function millisToMinutesAndSeconds(millis) {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}

export default props => {
  const history = useHistory();
  const [showOptions, toggleOptions] = useState(false);
  const [notification, setNotification] = useState("");

  let userInfo;

  try {
    userInfo = JSON.parse(localStorage.getItem("userInfo"));
  } catch (error) {
    console.error(error);
  }

  const copyLink = () => {
    var tempInput = document.createElement("input");
    tempInput.style = "position: absolute; left: -1000px; top: -1000px";
    tempInput.value = window.location.origin + "/sent/" + props._id;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    setNotification("link copied successfully");
    setTimeout(() => {
      setNotification("");
    }, 10000);
  };

  const handleDeleteRecord = async (id) => {
    try {
      await fetch(`${config.BASE_ENDPOINT}/api/record/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${userInfo.jwtAccessToken}`
        }
      }).then(resp => resp.json());
      setNotification("video deleted successfully");
      window.location.reload();
    } catch (error) {
      console.log(error);
      setNotification("an error occurred while attempting to delete the video");
    }
    setTimeout(() => {
      setNotification("");
    }, 10000);
  };

  const closeNotification = () => {
    setNotification("");
  };

  const suffix = props.large ? "__large" : "";
  const isGoogle = props.resourceHolder === "GOOGLE";

  document.body.onclick = (e) => {
    document.body.click();
    !props.large && e.target.id !== 'options-button' && toggleOptions(false);
  };

  return (
    <section className={`video__card${suffix}`}>
      {
        notification && <Toaster message={notification} onClose={closeNotification} />
      }
      {
        !props.large ?
          (<div
            alt="video thumbnail"
            style={{
              background: `url(${props.thumbnailLink + (isGoogle ? "=s500" : "")}) no-repeat center center`,
              WebkitBackgroundSize: "cover",
              MozBackgroundSize: "cover",
              backgroundSize: "cover"
            }}
            className={`thumbnail`}
            onClick={(e) => {
              if (e.target !== e.currentTarget) {
                return;
              }

              history.push(`/video/${props._id}`, { record: props });
            }}>
            <div className="link__copy">
              <button className="no__border no__background" onClick={copyLink}>
                <i className="link__copy__icon"></i>
              </button>
            </div>
          </div>) :
          (
            <iframe title={props.name} src={(props.viewLink || "").replace("view", "preview")} allow="autoplay" className="thumbnail__large" />
          )
      }
      <div className={`video__description${suffix}`}>
        <p>
          <NavLink
            className="regular__text__bold boring__link"
            to={{
              pathname: `/video/${props._id}`,
              state: { record: props }
            }}>
            {props.name}
          </NavLink>
          <span className="regular__text pull__right" style={{ position: 'relative' }}>
            {
              props.large ?
                moment(new Date(props.created_at)).fromNow() :
                <>
                  <button
                    type="button"
                    id="options-button"
                    className="options__icon__button"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleOptions(!showOptions);
                    }}>
                    <i className="options__icon"></i>
                  </button>
                  <div className={`options__dropdown ${showOptions ? "display__block" : ""}`}>
                    <ul>
                      <li><button onClick={() => handleDeleteRecord(props._id)} style={{ paddingLeft: "0" }} className="no__border no__background">Delete</button></li>
                      <li><a style={{ paddingLeft: "0" }} href={props.downloadLink} className="no__border no__background" rel="noopener noreferrer" target="_blank" download>Download</a></li>
                    </ul>
                  </div>
                </>
            }
          </span>
        </p>
        <p className="regular__text">
          {props.duration ? `${millisToMinutesAndSeconds(Math.abs(props.duration))} mins` : 'N/A'}
          <span className="pull__right">
            {
              !props.large && moment(new Date(props.created_at)).fromNow()
            }
          </span>
        </p>
        <hr className="video__description__hr" />
      </div>
      <div className="video__footer">
        <img src={props?.user?.picture || avatar} alt="Avatar" style={{ opacity: "0.7" }} className={`video__icon${suffix} avatar`} />
        <div className={`user__info${suffix}`}>
          <div className="regular__text">By {props?.user?.name}</div>
          <div className="regular__text">{new Date(props?.created_at).toLocaleDateString()}</div>
        </div>
      </div>
    </section>
  )
}