/*global window*/

import React, { useEffect, useState } from "react";
import QueryString from "qs";
import Config from "../config";
import { useLocation, useHistory } from "react-router-dom";
import hexcord_logo from "../images/hexcord_logo_wordmark.svg";
import Loader from "../components/loader/index";
import "./login.css";
import constants from "../constants";

var port = { postMessage: () => { } };

if (window.chrome?.runtime) {
  port = window.chrome?.runtime.connect(Config.EXTENSION_ID);
}

export default props => {
  const location = useLocation();
  const history = useHistory();

  const [isLoading, setLoadingStatus] = useState(false);

  useEffect(() => {
    const checkSocialAuth = async () => {
      const query = QueryString.parse(location.search, { ignoreQueryPrefix: true })

      if (query.code && query.state) {
        setLoadingStatus(true);
        try {
          const isDropbox = /dropbox/i.test(query.state);
          let callbackURL;
          if (isDropbox) {
            callbackURL = `${Config.BASE_ENDPOINT}/oauth/dropbox/callback${location.search}`
          } else {
            callbackURL = `${Config.BASE_ENDPOINT}/oauth/google/callback${location.search}`;
          }
          const user = await fetch(callbackURL).then(resp => resp.json());
          const userInfo = { ...user, tokenInfo: null };
          props.dispatch({ type: constants.SET_USER_INFO, payload: userInfo });
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          port.postMessage({ type: isDropbox ? "DROPBOX_LOGIN" : "GOOGLE_LOGIN", ...user });
        } catch (error) {
          console.log(error);
        } finally {
          history.push('/');
        }
      }
    };

    checkSocialAuth();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    isLoading ?
      <Loader /> :
      <main role="main">
        <div className="main__container">
          <header style={{ marginTop: "100px", marginBottom: "120px" }}>
            <div className="logo"><img src={hexcord_logo} alt="Horus Logo"></img></div>
          </header>
          <div className="container__body">
            <div style={{ margin: "auto" }}>
              <h1 className="signup__text">Sign Up</h1>
              <div className="signup__description">Sign up to record your videos, download them and share with friends and colleagues.</div>
              <form style={{ width: "100%", marginTop: "60px" }} action={`${Config.BASE_ENDPOINT}/oauth/google`}>
                <div className="center__text">
                  <button type="submit" className="login__button google__button">
                    <i className="icon google"></i>
                    <div className="login__divider"></div>
                   Continue with Google
                </button>
                </div>
              </form>
              <form style={{ width: "100%", marginTop: "30px" }} action={`${Config.BASE_ENDPOINT}/oauth/dropbox`}>
                <div className="center__text">
                  <button type="submit" className="login__button dropbox__button">
                    <i style={{ height: "19px", width: "20px" }} className="icon dropbox"></i>
                    <div className="login__divider"></div>
                   Continue with Dropbox
                </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
  );
};