import React, { createRef, useState, useEffect } from "react";
import VideoCard from "../video_card/VideoCard";
import "./video.css"
import { useRouteMatch } from "react-router-dom";
import config from "../../config";
import Loader from "../loader";


export default props => {
  const { params } = useRouteMatch();
  const mobileOptionsRef = createRef();
  const mobileInfoRef = createRef();
  const [video, setVideo] = useState({});

  const [isLoading, setLoadingStatus] = useState(false);

  const copyLink = () => {
    var tempInput = document.createElement("input");
    tempInput.style = "position: absolute; left: -1000px; top: -1000px";
    tempInput.value = window.location.origin + "/sent/" + video?._id;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    alert("link copied successfully");
  };

  useEffect(() => {
    const getVideo = async () => {
      setLoadingStatus(true);
      try {
        const res = await fetch(`${config.BASE_ENDPOINT}/api/record/shared/${params.videoId}`,).then(res => res.json());
        setVideo(res);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingStatus(false);
      }
    };

    getVideo();
  }, [params.videoId]);

  return (
    isLoading ?
      <Loader /> :
      <main className="video__wrapper">
        <section className="main__container video__main__container">
          <div className="video__container__header__mobile">
            <p className="regular__text__bold">{video?.name}</p>
            <button className="no__border no__background" onClick={() => { mobileOptionsRef.current.classList.toggle("hidden"); }}><i className="options__icon"></i></button>
          </div>
          <div style={{ width: "60%" }} className="container__header">
            <div className="container__header__options tooltip">
              <span className="tooltiptext">Download</span>
              <a href={video?.downloadLink} rel="noopener noreferrer" target="_blank" download>
                <i className="header__options__icon download" data-text="Download">
                </i>
              </a>
            </div>
          </div>
          <div style={{ marginTop: "24px" }} className="container__body">
            <VideoCard {...video} large />
            <aside className="float__block__right" style={{ width: "35%" }}>
              <div className="share__video">
                <div>
                  <p className="regular__text__bold">Share Video</p>
                </div>
                <div>
                  <p className="regular__text" style={{ marginTop: "20px", lineHeight: "1" }}>{`${(window.location.origin + "/sent/" + video?._id).slice(0, 50)}...`}</p>
                  <button onClick={copyLink} type="button" className="copy__button">Copy link</button>
                </div>
              </div>
            </aside>
          </div>
          <div ref={mobileOptionsRef} className="mobile__video__options hidden" onClick={() => { mobileOptionsRef.current.classList.toggle("hidden"); }}>
            <ul style={{ height: "225px" }} onClick={e => { e.stopPropagation(); }}>
              <li><a href={video?.downloadLink} rel="noopener noreferrer" target="_blank" download><i className="nav__icon download"></i>Download</a></li>
              <li><button onClick={copyLink} className="no__border no__background"><i className="nav__icon link"></i>Copy link</button></li>
              <li><button className="no__border no__background"><i className="nav__icon info" style={{ marginRight: "36px" }}></i>Info</button></li>
            </ul>
          </div>
          <div ref={mobileInfoRef} className="mobile__video__options hidden" onClick={() => { mobileInfoRef.current.classList.toggle("hidden"); }}>
            <ul style={{ height: "265px" }} onClick={e => { e.stopPropagation(); }}>
              <li>
                <span className="regular__text">Video name</span>
                <p style={{ margin: "0", lineHeight: "0" }} className="regular__text__bold">{video?.name}</p>
              </li>
              <li>
                <span className="regular__text">Created</span>
                <p style={{ margin: "0", lineHeight: "0" }} className="regular__text__bold">{new Date(video?.created_at).toLocaleDateString()}</p>
              </li>
              <li>
                <span className="regular__text">Owner</span>
                <p style={{ margin: "0", lineHeight: "0" }} className="regular__text__bold">{video?.name}</p>
              </li>
            </ul>
          </div>
        </section>
      </main>
  )
}