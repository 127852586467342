import React, { useState, useReducer, useEffect } from 'react';
import { Switch, BrowserRouter, Route } from "react-router-dom";
import Header from './components/header/Header';
import Index from './components/index/Index';
import Shared from './components/shared/Index';
import Login from './pages/Login';
import Video from './components/video/Video';
import Extension from './components/extension';
import SharedLinkVideo from './components/shared_video/Video';
import constants from './constants';

let userInfo;

try {
  userInfo = localStorage.getItem("userInfo");
  userInfo = JSON.parse(userInfo);
} catch (error) {
  console.error(error);
  userInfo = {};
}

const initialState = {
  myVideos: [],
  sharedVideos: [],
  searchResults: [],
  notifications: [],
  isLoading: false,
  userInfo: { ...userInfo }
};

const reducer = (state, action) => {
  switch (action.type) {
    case constants.GET_MY_VIDEOS:
      return {
        ...state,
        myVideos: action.payload
      };
    case constants.GET_SHARED_VIDEOS:
      return {
        ...state,
        sharedVideos: action.payload
      };
    case constants.SEARCH_RESULT:
      return {
        ...state,
        searchResults: action.payload
      };
    case constants.GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload
      };
    case constants.RECEIVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.concat(action.payload)
      };
    case constants.IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case constants.SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload
      };
    default:
      return state;
  }
};

function App({ messenger = {} }) {
  messenger.onMessage(payload => {
    console.log('Message received. ', payload);
    if (!payload) return;

    dispatch({
      type: constants.RECEIVE_NOTIFICATION,
      payload
    });
  });

  const [state, dispatch] = useReducer(reducer, initialState);

  const [showExtension, shouldShowExtension] = useState(false);

  const openExtension = () => {
    shouldShowExtension(true);
  };

  const closeExtension = () => {
    shouldShowExtension(false);
  };

  const getNotifications = async () => {
    dispatch({ type: constants.IS_LOADING, isLoading: true });

    try {
      const result = await fetch(`${constants.NOTIFICATION_ENDPOINT}/user/notifications`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${(userInfo || {}).jwtAccessToken}`
        }
      }).then(resp => resp.json());

      dispatch({
        type: constants.GET_NOTIFICATIONS,
        payload: result?.length ? result : []
      });

    } catch (error) {
      console.error(error);
    } finally {
      dispatch({ type: constants.IS_LOADING, isLoading: false });
    }
  };

  useEffect(() => {
    getNotifications();
    setTimeout(() => {
      if (!document.getElementById('hexcord-checker')) {
        //window.location.href = "https://chrome.google.com/webstore/detail/apkdlkpcghonmghkbkhhjpaemnmjahgi";
      }
    }, 5000);
  }, []);

  return (
    <BrowserRouter>
      {
        showExtension && <Extension closeExtension={closeExtension} />
      }
      <Switch>
        <Route path="/" exact>
          <Header dispatch={dispatch} notifications={state.notifications} userInfo={state.userInfo} />
          <Index videos={state.myVideos} dispatch={dispatch} isLoading={state.isLoading} openExtension={openExtension} />
        </Route>
        <Route path="/shared" exact>
          <Header dispatch={dispatch} notifications={state.notifications} />
          <Shared videos={state.sharedVideos} dispatch={dispatch} isLoading={state.isLoading} openExtension={openExtension} />
        </Route>
        <Route path="/video/:videoId">
          <Header dispatch={dispatch} notifications={state.notifications} />
          <Video openExtension={openExtension} userInfo={state.userInfo} />
        </Route>
        <Route path="/sent/:videoId">
          <Header dispatch={dispatch} notifications={state.notifications} />
          <SharedLinkVideo />
        </Route>
        <Route path="/login" exact><Login dispatch={dispatch} /></Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
