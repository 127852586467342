import React from 'react';
import style from './index.module.css';

const Modal = ({ message, isLoader, fileName, custom, isWarning, handleClick = () => { }, closeModal }) => {

  return (
    <div className={style.ex__modal}>
      <button onClick={closeModal} id="close-modal" style={{ float: "right", marginTop: "5%", marginRight: "5%" }} type="button" className={style.ex__link__button}>
        <i style={{ width: "14px", height: "15px" }} className={[style.ex__icon, style.ex__close].join(" ")}></i>
      </button>
      <div>
        {
          message && (
            <p>
              {message}
            </p>
          )
        }
        {
          isLoader ? (
            <div style={{ marginTop: "25px" }}>
              <i style={{ width: "21px", height: "25px", float: "left" }} className={[style.ex__icon, style.ex__save].join(" ")}></i>
              <div className={style.ex__save__loader}>
                <div className={style.ex__light__text}>{fileName}</div>
                <div className={style.ex__loader__progress}>
                  <div className={style.ex__loading_indicator}></div>
                </div>
              </div>
            </div>
          ) :
            custom || (
              <>
                <button
                  id="modal-button-main"
                  type="button"
                  onClick={handleClick}
                  className={[style.ex__modal__button, isWarning ? style.ex__warning__modal__button : style.ex__success__modal__button].join(" ")}>
                  Yes, I want to
              </button>
                <button id="modal-button-secondary" onClick={closeModal} type="button" className={style.ex__link__button}>No, I don't want to</button>
              </>
            )
        }
      </div>
    </div >
  );
};

export default Modal;