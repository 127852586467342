import React, { useState } from "react";
import style from "./index.module.css";

const getAdvancedSettingsOptions = (type, mediaDevices = [], setDevice, selected) => (
  <div id="advanced-settings-option-mic" className={style.advanced__settings__option__container}>
    <main>
      <p>{type === "videoinput" ? "Select a Camera" : "Select your Microphone"}</p>
      <ul className={style.advanced__settings__option__list}>
        {
          mediaDevices.reduce((acc, device, i) => {
            switch (device.kind) {
              case type:
                acc.push(<li key={i}>
                  <div className={style.radio__item} style={{ textAlign: "left", color: "#FFFFFF", width: "100%", padding: "0px" }}>
                    <input name="mic-device" onChange={e => {
                      setDevice(e.target.value);
                    }} value={device.deviceId} id={device.deviceId} type="radio" checked={selected === device.deviceId} />
                    <label className={style.list__check} style={{ cursor: "pointer" }} htmlFor={device.deviceId}>
                      {device.label || "Default"}
                    </label>
                  </div>
                </li>);
                break;
              default:
            }
            return acc;
          }, [])
        }
      </ul>
    </main>
  </div>
);

const AdvancedSettings = props => {
  const [type, setType] = useState("");

  return (
    <horus-advanced-settings id="advanced-settings-view">
      <div className={style.advanced__settings__container}>
        <main>
          <p>Audio & Video Settings</p>
          <div className={style.advanced_settings_body}>
            <button onClick={() => setType("videoinput")} id="cam-select" type="button" style={{ cursor: "pointer" }} className={[style.no__border, style.no__background].join(" ")}><i className={style.cam_select}></i></button>
            <button onClick={() => setType("audioinput")} id="mic-select" type="button" style={{ cursor: "pointer" }} className={[style.no__border, style.no__background].join(" ")}><i className={style.mic_select}></i></button>
          </div>
          <button onClick={props.handleSubmit} id="submit-advanced-options" type="button" style={{ cursor: "pointer" }} className={[style.no__border, style.advanced__done_button].join(" ")}>Done</button>
        </main>
      </div>
      {type && getAdvancedSettingsOptions(type, props.availableDevices, type === "videoinput" ? props.setVideoDevice : props.setAudioDevice, type === "videoinput" ? props.videoDevice : props.audioDevice)}
    </horus-advanced-settings>
  );
};

export default AdvancedSettings;