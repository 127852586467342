export default {
  HORUS_EL: "horus-el",
  HORUS_TIMER: "horus-timer",
  HORUS_MODAL: "horus-modal",
  HORUS_BACKDROP: "horus-backdrop",
  SCREEN_RECORDER_VIDEO: "screen-recorder-video",
  SAVE_RECORDING: "save-recording",
  DOWNLOAD_RECORDING: "download-recording",
  PAUSE_RECORDING: "pause-recording",
  RESUME_RECORDING: "resume-recording",
  RESTART_RECORDING: "restart-recording",
  CANCEL_RECORDING: "cancel-recording",
  // BASE_ENDPOINT: "http://localhost:9000",
  BASE_ENDPOINT: "https://api.hexcord.com",
  // NOTIFICATION_ENDPOINT: "http://localhost:8070",
  NOTIFICATION_ENDPOINT: "https://notification.hexcord.com",
  SCREEN_ONLY: "SCREEN_ONLY",
  CAM_ONLY: "CAM_ONLY",
  SCREEN_CAM: "SCREEN_CAM",
  VIDEO_OPTIONS: "video-options",
  MODAL_BUTTON_MAIN: "modal-button-main",
  MODAL_BUTTON_SECONDARY: "modal-button-secondary",
  CLOSE_MODAL: "close-modal",
  GET_MY_VIDEOS: "GET_MY_VIDEOS",
  GET_SHARED_VIDEOS: "GET_SHARED_VIDEOS",
  SEARCH_RESULT: "SEARCH_RESULT",
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  RECEIVE_NOTIFICATION: "RECEIVE_NOTIFICATION",
  IS_LOADING: "IS_LOADING",
  TIME_CHECK: "time-check",
  SET_USER_INFO: "SET_USER_INFO"
};