import React from 'react';
import ReactDOM from 'react-dom';
import firebase from "firebase/app";
import "firebase/messaging";
import 'firebase/analytics';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import config from './config';

var messenger = { onMessage: () => { } };

async function init() {
  try {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let lastRefresh = new Date(localStorage.getItem("lastRefresh"));

    if (Math.abs(new Date() - lastRefresh) / 36e5 >= 22 && !/login|sent/i.test(window.location.pathname)) {
      userInfo = await fetch(`${config.BASE_ENDPOINT}/oauth/refresh_token`, {
        method: "GET",
        headers: {
          "Refresh-Token": (userInfo || {}).refreshToken
        }
      })
        .then(resp => {
          if (!resp.ok) throw new Error(resp.text());
          return resp.json();
        });

      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      localStorage.setItem("lastRefresh", new Date());
    }

    initializeFirebaseMessaging(userInfo)
  } catch (error) {
    console.error(error);
    if (!/login|sent/i.test(window.location.pathname)) {
      window.location.pathname = "/login";
    }
  }
}

function initializeFirebaseMessaging(userInfo) {
  const firebaseConfig = {
    apiKey: "AIzaSyBSkHVUweWIKauXbj-h49WwysgxQyvvnkY",
    authDomain: "hexcord-88411.firebaseapp.com",
    databaseURL: "https://hexcord-88411.firebaseio.com",
    projectId: "hexcord-88411",
    storageBucket: "hexcord-88411.appspot.com",
    messagingSenderId: "83392944820",
    appId: "1:83392944820:web:39cee504d18e7adb6155e1",
    measurementId: "G-W8PL4RBFZ9"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
  messenger = firebase.messaging();
  messenger.usePublicVapidKey(config.FIREBASE_KEY);
  messenger.getToken().then(token => {
    if (token) {
      fetch(`${config.BASE_ENDPOINT}/api/user/clients`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userInfo.jwtAccessToken}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          token
        })
      }).then(response => response.json())
        .then(res => {
          localStorage.setItem("notificationClient", token);
        });
    }
  }).catch((err) => {
    console.log('Unable to retrieve refreshed token ', err);
  });

  messenger.onTokenRefresh(() => {
    messenger
      .requestPermission()
      .then(() => messenger.getToken())
      .then((token) => {
        const expired = localStorage.getItem("notificationClient");
        fetch(`${config.BASE_ENDPOINT}/api/user/clients`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userInfo.jwtAccessToken}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            token,
            expiredToken: expired
          })
        }).then(response => response.json())
          .then(res => {
            localStorage.setItem("notificationClient", token);
          });
      }).catch((err) => {
        console.log('Unable to retrieve refreshed token ', err);
      });
  });
}

init()
  .then(() => {
    ReactDOM.render(
      <React.StrictMode>
        <App messenger={messenger} />
      </React.StrictMode>,
      document.getElementById('root')
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
  });

