import React, { useEffect } from "react";
import VideoCard from "../video_card/VideoCard";
import Loader from "../loader/index";
import style from "./index.module.css";
import config from "../../config";
import constants from "../../constants";

const Home = props => {

  useEffect(() => {
    const run = async () => {
      props.dispatch({ type: constants.IS_LOADING, isLoading: true });
      try {
        let userInfo = localStorage.getItem("userInfo");
        userInfo = JSON.parse(userInfo);

        const result = await fetch(`${config.BASE_ENDPOINT}/api/record`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${(userInfo || {}).jwtAccessToken}`
          }
        }).then(resp => resp.json());

        props.dispatch({ type: constants.GET_MY_VIDEOS, payload: result });
      } catch (error) {
        console.error(error);
      } finally {
        props.dispatch({ type: constants.IS_LOADING, isLoading: false });
      }
    };

    run();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    props.isLoading ?
      <Loader /> :
      <main>
        <section className="main__container">
          <div className="container__header">
            <p className="regular__text__bold">My Videos <button onClick={props.openExtension} type="button" className="new__video__button">New Video</button></p>
          </div>
          <div className={style.container__body}>
            {
              (props.videos.items || []).map(record => <VideoCard key={record._id} {...record} />)
            }
          </div>
        </section>
      </main>
  );
};

export default Home;