import React from 'react';
import style from './style.module.css';

const Toaster = ({ message, onClose }) => {
  return (
    <div className={style.toaster}>
      <p>
        <span>{message}</span>
        <button type="button" className={style.toaster__icon__button} onClick={onClose}>
          <span className={style.times__icon}></span>
        </button>
      </p>
    </div>
  );
}

export default Toaster;