/* eslint-disable no-fallthrough */
/*global window, userStream*/

import React, { useEffect, createRef, useState } from 'react';
import style from "./index.module.css";
import Config from "../../config";
import constants from "../../constants";
import Modal from "./modal";
import { generateName, getRandomInt } from "./generator";
import AdvancedSettings from './settings';

var port = { postMessage: () => { }, onMessage: { addListener: () => { } } };
var glob_timer;

if (window.chrome?.runtime) {
  port = window.chrome?.runtime.connect(Config.EXTENSION_ID);
}

const downloadRecording = () => {
  port.postMessage({
    type: "DOWNLOAD_BACKGROUND_RECORDING",
    fileName: `${generateName()}_${getRandomInt(1, 1000)}.webm`
  });
};

const pauseRecording = () => {
  port.postMessage({ type: "PAUSE_BACKGROUND_RECORDING" });
};

const resumeRecording = () => {
  port.postMessage({ type: "RESUME_BACKGROUND_RECORDING" });
};

const initiateTimer = (initial = 0) => {
  var minutesLabel = document.getElementById("minutes");
  var secondsLabel = document.getElementById("seconds");
  var totalSeconds = initial;
  glob_timer = setInterval(setTime, 1000);

  function setTime() {
    ++totalSeconds;
    secondsLabel.innerHTML = pad(parseInt(totalSeconds % 60));
    const minutes = totalSeconds / 60;
    minutesLabel.innerHTML = pad(parseInt(minutes));
    if (minutes > 14) {
      document.getElementById(constants.TIME_CHECK).style.color = "red";
    }
  }

  function pad(val) {
    var valString = val + "";
    if (valString.length < 2) {
      return "0" + valString;
    } else {
      return valString;
    }
  }
};

const SaveForm = props => {
  const [isYoutubeUpload, shouldUploadToYoutube] = useState(false);
  const [fileName, setFileName] = useState("");

  const saveRecording = () => {
    const name = fileName || `${generateName()} ${getRandomInt(1, 1000)}`;
    props.setModalInfo({ message: "Saving", fileName: name, isLoader: true });
    port.postMessage({ "type": "SAVE_BACKGROUND_RECORDING", fileName: name, isYoutubeUpload });
  };

  return (
    <form onSubmit={e => { e.preventDefault(); saveRecording(); }}>
      <p className={style.ex__modal__form__header}>How about you give your video a name?</p>
      <input className={style.ex__modal__input} onChange={e => setFileName(e.target.value)} type="text" placeholder="Enter File Name" required />
      <input style={{ float: "left", margin: "5px 0" }} value={isYoutubeUpload} onChange={e => shouldUploadToYoutube(e.target.checked)} type="checkbox" placeholder="Upload recording to youtube" />
      <div className={style.ex__modal__actions}>
        <button className={style.ex__modal__button__1} type="submit">Save</button>
        <button onClick={e => saveRecording()} className={`no__border no__background pointer__cursor ${style.ex__modal__link}`}>Skip</button>
      </div>
    </form>
  )
};

const getVideoSavedModal = () => {
  return (
    <div id="save-confirm-wrapper" className={style.ex__save__confirm__wrapper}>
      <span className={style.ex__checkmark}>
      </span>
      <p>Video saved successfully.</p>
    </div>
  );
};

const Extension = props => {
  port.onMessage.addListener(async (message) => {
    switch (message.type) {
      case "BEGIN_COUNTDOWN":
        startCountdown();
        break;
      case "END_COUNTDOWN":
        stopCountdown();
        initiateTimer();
        break;
      case "HAS_PAUSED_RECORDING":
        shouldPause(true);
        break;
      case "HAS_RESUMED_RECORDING":
        shouldPause(false);
        break;
      case "FINISH_SAVING_BACKGROUND_RECORDING":
        closeModal();
        setSavedStatus(true);
        await new Promise(resolve => {
          setTimeout(() => {
            setSavedStatus(false);
            resolve(true);
          }, 3000);
        });
      case "FINISH_CANCELLING_BACKGROUND_RECORDING":
        closeModal();
        cleanResources();
        break;
      case "SHOW_FILE_SAVING_MODAL":
        setModalInfo({ message: "Saving", fileName: message.fileName, isLoader: true });
        break;
      default:
        console.log("dont know what to do here yet");
    }
    return true;
  });

  const videoElem = createRef();

  const [showVideo, shouldShowVideo] = useState(true);
  const [camOption, setCamOption] = useState("SCREEN_CAM");
  const [useMic, shouldUseMic] = useState(true);
  const [goLive, shouldGoLive] = useState(false);
  const [audioDevice, setAudioDevice] = useState();
  const [videoDevice, setVideoDevice] = useState();
  const [availableDevices, setAvailableDevices] = useState([]);
  const [showForm, shouldShowForm] = useState(true);
  const [isPaused, shouldPause] = useState(false);
  const [modalInfo, setModalInfo] = useState();
  const [showTimer, shouldShowTimer] = useState(false);
  const [isRecording, setRecordingStatus] = useState(false);
  const [showAdvancedSettings, shouldShowAdvancedSettings] = useState(false);
  const [isSaved, setSavedStatus] = useState(false);

  let timerInterval;

  const startCountdown = () => {
    shouldShowTimer(true);
    let count = 3;
    timerInterval = setInterval(() => {
      document.getElementById("ex-video-timer").innerHTML = --count;
    }, 1000);
  };

  const stopCountdown = () => {
    clearInterval(timerInterval);
    shouldShowTimer(false);
    setRecordingStatus(true);
  };

  const closeModal = () => setModalInfo(undefined);

  const cleanResources = () => {
    if (window.userStream && window.userStream instanceof MediaStream) {
      window.userStream.getTracks().forEach(track => track.stop());
    }
    window.userStream = {};

    clearInterval(glob_timer);

    props.closeExtension();
  };

  const saveRecordingPrompt = () => {

    setModalInfo({
      message: "Would you like to save this video you just recorded?",
      closeModal,
      handleClick: () => {
        setModalInfo({
          closeModal,
          custom: (<SaveForm setModalInfo={setModalInfo} />)
        });
      }
    });
  };

  const cancelRecordingPrompt = () => {
    setModalInfo({
      isWarning: true,
      message: "Are you sure you want to delete this video?",
      closeModal,
      handleClick: () => {
        closeModal();
        port.postMessage({ "type": "CANCEL_BACKGROUND_RECORDING" });
      }
    });
  };

  const restartRecordingPrompt = () => {
    setModalInfo({
      isWarning: true,
      message: "Are you sure you want to restart? This action will delete the previous video.",
      closeModal,
      handleClick: () => {
        closeModal();
        port.postMessage({ type: "RESTART_BACKGROUND_RECORDING" });
      }
    });
  };

  const activateCamera = async (videoId, audioId) => {
    let constraints = {
      camera: true,
      video: { width: 200, height: 200 },
      audio: false
    };

    if (videoId) {
      constraints['video'].deviceId = videoId;
    }

    if (audioId) {
      constraints['audio'] = {
        deviceId: audioId
      };
    }

    try {
      window.userStream = await navigator.mediaDevices.getUserMedia(constraints);
      const videoTracks = userStream.getVideoTracks();
      userStream.onremovetrack = function () {
        console.log(`User stream ended - ${videoTracks[0].label}`);
      };

      // videoElem.current.srcObject = userStream;
      document.getElementById("screen-recorder-video").srcObject = userStream;
      return userStream;
    } catch (error) {
      console.error(error);
    }
  };

  const deactivateCamera = () => {
    if (userStream && typeof userStream.getTracks === "function") {
      userStream.getTracks().forEach(track => track.stop());
    }

    window.userStream = {};
    shouldShowVideo(false);
  };

  const startCapture = async (e) => {
    e.preventDefault();

    switch (camOption) {
      case constants.CAM_ONLY:
        cleanResources();
      case constants.SCREEN_ONLY:
        deactivateCamera();
        port.postMessage({
          type: "START_BACKGROUND_RECORDING",
          camOption,
          useMic,
          audioDevice,
          videoDevice,
          userStream: (userStream || {}).id
        });
        break;
      case constants.SCREEN_CAM:
        await activateCamera(videoDevice);
      default:
        port.postMessage({
          type: "START_BACKGROUND_RECORDING",
          camOption,
          useMic,
          audioDevice,
          videoDevice,
          userStream: (userStream || {}).id
        });
    }

    shouldShowForm(false);
  };

  const goLivePrompt = () => {
    setModalInfo({
      message: "Are you sure you want to go live?",
      closeModal,
      handleClick: () => {
        shouldGoLive(true);
        port.postMessage({ "type": "GO_LIVE" });
        closeModal();
      }
    });
  };

  useEffect(() => {
    let isSubscribed = true;

    activateCamera();
    navigator.mediaDevices.enumerateDevices()
      .then(devices => {
        if (isSubscribed) {
          setAvailableDevices(devices);
        }
      });

    return () => {
      isSubscribed = false;
      clearInterval(glob_timer);
    };
  }, []);

  return (
    <horus-el id="horus-el">
      {
        showForm && (
          <main className={style.main__container}>
            <div className={style.inner__container}>
              <h1 className={[style.main__header, style.primary__bold__text].join(" ")}>Record</h1>
              <div className={style.main__preview}>
                <img id="image_preview" className={style[`${camOption.toLowerCase()}__preview`]} alt="screen mode preview" />
              </div>
              <form id="video-recorder-form" onSubmit={startCapture}>
                <div className={style.cam__options}>
                  <div className={[style.option, camOption === "CAM_ONLY" ? style.active : ""].join(" ")}>
                    <div className={style.circular__icon}><i className={[style.icon, style.cam__only].join(" ")}></i></div>
                    <div className={style.radio__item}>
                      <input checked={camOption === "CAM_ONLY"} onChange={e => setCamOption(e.target.value)} type="radio" className={style.radio__icon} id="cam_only" name="cam_option" value="CAM_ONLY" />
                      <label htmlFor="cam_only" className={[style.secondary__bold__text, style.for__radio].join(" ")}>Cam Only</label>
                    </div>
                  </div>
                  <div className={[style.option, camOption === "SCREEN_ONLY" ? style.active : ""].join(" ")}>
                    <div className={style.circular__icon}><i style={{ width: "16px", height: "15px" }} className={[style.icon, style.screen__only].join(" ")}></i></div>
                    <div className={style.radio__item}>
                      <input checked={camOption === "SCREEN_ONLY"} onChange={e => setCamOption(e.target.value)} type="radio" className={style.radio__icon} id="screen_only" name="cam_option" value="SCREEN_ONLY" />
                      <label htmlFor="screen_only" className={[style.secondary__bold__text, style.for__radio].join(" ")}>Screen Only</label>
                    </div>
                  </div>
                  <div className={[style.option, camOption === "SCREEN_CAM" ? style.active : ""].join(" ")}>
                    <div className={style.circular__icon}><i style={{ width: "16px", height: "15px" }} className={[style.icon, style.screen__cam].join(" ")}></i></div>
                    <div className={style.radio__item}>
                      <input checked={camOption === "SCREEN_CAM"} onChange={e => setCamOption(e.target.value)} type="radio" className={style.radio__icon} id="screen_cam" name="cam_option" value="SCREEN_CAM" />
                      <label htmlFor="screen_cam" className={[style.secondary__bold__text, style.for__radio].join(" ")}>Screen + Cam</label>
                    </div>
                  </div>
                </div>
                <div className={[style.audio__options, style.primary__bold__text].join(" ")}>Microphone Audio
                  <label style={{ float: 'right' }} className={style.switch}>
                    <input id="use_mic" type="checkbox" onChange={e => shouldUseMic(e.target.checked)} checked />
                    <span className={[style.slider, style.round].join(" ")}></span>
                  </label>
                </div>
                <div className={style.audio__options}>
                  <button
                    type="button"
                    style={{ cursor: "pointer", padding: "0px", fontSize: "16px" }}
                    id="advanced-settings-toggle"
                    className={[style.no__border, style.no__background, style.primary__bold__text].join(" ")}
                    onClick={() => {
                      shouldShowAdvancedSettings(!showAdvancedSettings);
                    }}
                  >
                    Advanced Settings
                    </button>
                </div>
                {
                  showAdvancedSettings && <AdvancedSettings
                    availableDevices={availableDevices}
                    setAudioDevice={setAudioDevice}
                    setVideoDevice={setVideoDevice}
                    audioDevice={audioDevice}
                    videoDevice={videoDevice}
                    handleSubmit={() => shouldShowAdvancedSettings(!showAdvancedSettings)} />
                }
                <button type="submit" className={style.large__button} id="start-recording">Start Recording</button>
              </form>
            </div>
          </main>
        )
      }
      <div id="horus-backdrop" className={[style.ex__video__backdrop, !showForm && style.no__background].join(" ")}>
        {
          isSaved ? (
            <horus-modal id="horus-modal" class={style.ex__video__backdrop}>
              {getVideoSavedModal()}
            </horus-modal>) :
            <>
              <div id={constants.TIME_CHECK} className={style.ex__time__check}>
                <label id="minutes">00</label>:<label id="seconds">00</label>
              </div>
              {
                showVideo && <video className={style.ex__avatar__video} ref={videoElem} id='screen-recorder-video' autoPlay />
              }
              <div id="video-options" className={style.ex__video__options}>
                {
                  isPaused ?
                    <button onClick={resumeRecording} className={`${style.ex__icon__button} tooltip`} id='resume-recording'><span className="tooltiptext">Resume</span><i style={{ width: "16px", height: "20px" }} className={[style.ex__icon, style.ex__resume].join(" ")}></i></button> :
                    <button onClick={pauseRecording} className={`${style.ex__icon__button} tooltip`} id='pause-recording'><span className="tooltiptext">Pause</span><i style={{ width: "17px", height: "22px" }} className={[style.ex__icon, style.ex__pause].join(" ")}></i></button>
                }
                <button onClick={saveRecordingPrompt} className={`${style.ex__icon__button} tooltip`} id='save-recording'><span className="tooltiptext">Save</span><i className={[style.ex__icon, style.ex__stop].join(" ")}></i></button>
                <button onClick={restartRecordingPrompt} className={`${style.ex__icon__button} tooltip`} id='restart-recording'><span className="tooltiptext">Restart</span><i style={{ width: "25px", height: "22px" }} className={[style.ex__icon, style.ex__restart].join(" ")}></i></button>
                <button onClick={downloadRecording} className={`${style.ex__icon__button} tooltip`} id='download-recording'><span className="tooltiptext">Download</span><i className={[style.ex__icon, style.ex__download].join(" ")}></i></button>
                {
                  isRecording && (
                    <button onClick={goLivePrompt} disabled={goLive} className={`${style.ex__icon__button} tooltip ${goLive ? style.is__live__button : ""}`} id='go-live'><span className="tooltiptext">Go Live</span><i className={[style.ex__icon, style.ex__live].join(" ")}></i></button>
                  )
                }
                <button onClick={cancelRecordingPrompt} className={`${style.ex__icon__button} tooltip`} id='cancel-recording'><span className="tooltiptext">Cancel</span><i style={{ width: "14px", height: "14px" }} className={[style.ex__icon, style.ex__cancel].join(" ")}></i></button>
              </div>
              {
                modalInfo && (
                  <horus-modal id="horus-modal" class={style.ex__video__backdrop}>
                    <Modal {...modalInfo} />
                  </horus-modal>
                )
              }
            </>
        }
      </div>
      {
        showTimer && (
          <horus-timer id="horus-timer">
            <div className={style.ex__video__backdrop}>
              <div id="ex-video-timer" className={style.ex__video__timer}>
                3
              </div>
            </div>
          </horus-timer>
        )
      }
    </horus-el>
  )
}

export default Extension;